import { faGithub, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconsMapping from "../../assets/icons/iconsMapping";
import luis from "../../assets/images/team/luis.jpg";
import bill from "../../assets/images/team/will.jpg";
import jon from "../../assets/images/team/jon.png";
import React, { useState, useEffect } from "react";
import "./teamCard.css";

const imageMap = {
    luis: luis,
    bill: bill,
    jon: jon
};

const TeamCard = ({ social, name, position, instagram, github, linkedin, bio }) => {
    const [modal, setModal] = useState(false);
    const imageSrc = imageMap[name] || "";

    const toggleModal = () => {
        setModal(!modal);
    };

    useEffect(() => {
        if (modal) {
            document.body.classList.add('active-modal');
        } else {
            document.body.classList.remove('active-modal');
        }

        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                setModal(false);
            }
        };

        window.addEventListener('keydown', handleEscape);
        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, [modal]);

    const stopPropagation = (e) => e.stopPropagation();

    return (
        <>
            <div className="teamCard" onClick={toggleModal}>
                <div className="imgBx">
                    <img src={imageSrc} alt={name} />
                </div>

                <div className="content">
                    <div className="contentBx">
                        <h3>{name}<br /><span>{position}</span></h3>
                    </div>
                    {social && (
                        <ul className="sci">
                            <li style={{ '--i': 1 }}>
                                <a 
                                    target='_blank' 
                                    rel='noopener noreferrer' 
                                    href={instagram} 
                                    onClick={stopPropagation}
                                    aria-label={`${name}'s Instagram`}
                                >
                                    <FontAwesomeIcon className='icon' icon={faInstagram} />
                                </a>
                            </li>
                            <li style={{ '--i': 2 }}>
                                <a 
                                    target='_blank' 
                                    rel='noopener noreferrer' 
                                    href={github} 
                                    onClick={stopPropagation}
                                    aria-label={`${name}'s Github`}
                                >
                                    <FontAwesomeIcon className='icon' icon={faGithub} />
                                </a>
                            </li>
                            <li style={{ '--i': 3 }}>
                                <a 
                                    target='_blank' 
                                    rel='noopener noreferrer' 
                                    href={linkedin} 
                                    onClick={stopPropagation}
                                    aria-label={`${name}'s LinkedIn`}
                                >
                                    <FontAwesomeIcon className='icon' icon={faLinkedin} />
                                </a>
                            </li>
                        </ul>
                    )}
                </div>
            </div>

            {modal && (
                <div 
                    className="modal" 
                    role="dialog" 
                    aria-labelledby="modal-title" 
                    aria-describedby="modal-description"
                >
                    <div onClick={toggleModal} className="overlay"></div>
                    <div className="modal-content">
                        <h2 id="modal-title">{name}</h2>
                        <p id="modal-description">{bio}</p>
                        <button 
                            onClick={toggleModal} 
                            aria-label="Close Modal" 
                            className="close-modal"
                        >
                            <img src={iconsMapping.close} alt="Close" />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default TeamCard;