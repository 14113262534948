import "./products.css"

const Products = () => {

    return (
        <section id="products" className="products">
            <h2>Products</h2>
            
            <div className="product-category pool">
                <div className="text">
                    <h4>Pool Tables</h4>
                </div>
            </div>

            <div className="product-category machines">
                <div className="juckbox prod">
                    <div className="text">
                        <h4>Jukeboxes</h4>
                    </div>
                </div>
                <div className="basket prod">
                    <div className="text">
                        <h4>Basketball Games</h4>
                    </div>
                </div>
                <div className="atm prod">
                    <div className="text">
                        <h4>ATM</h4>
                    </div>
                </div>
            </div>
            
            <div className="product-category machines">
                <div className="hockey prod">
                    <div className="text">
                        <h4>FX Air Hockey</h4>
                    </div>
                </div>
                <div className="car prod">
                    <div className="text">
                        <h4>Car Games</h4>
                    </div>
                </div>
                <div className="box prod">
                    <div className="text">
                        <h4>Boxer Machines</h4>
                    </div>
                </div>
            </div>

            <div className="product-category machines">
                <div className="bike prod">
                    <div className="text">
                        <h4>Bike Machines</h4>
                    </div>
                </div>
                <div className="claw prod">
                    <div className="text">
                        <h4>Claw Machines</h4>
                    </div>
                </div>
                <div className="arcade prod">
                    <div className="text">
                        <h4>Arcade Machines</h4>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Products;