import { TeamCard } from "../../components";
import "./team.css";

const Team = () => {
    return (
        <div id="team" className="team">
            <h2>Our Team</h2>
            <div className="card_Container">
                <TeamCard 
                    name="bill" 
                    position="Godfather of Pool" 
                    social={false} 
                    bio="Coming soon..." 
                />
                <TeamCard 
                    name="jon" 
                    position="Owner" 
                    social={false} 
                    bio="?" 
                />
                <TeamCard 
                    name="luis" 
                    position="Web Developer" 
                    social={true} 
                    instagram="https://www.instagram.com/bringas.el.pelon/" 
                    github="https://github.com/LuisEstebanAcevedoBringas" 
                    linkedin="https://www.linkedin.com/in/luis-esteban-acevedo-bringas/" 
                    bio="I am a Computer Engineer with a Master’s Degree in Security Engineering and Information Technologies, currently pursuing a PhD in Informatics. I have extensive expertise in Python, focusing on Stable Diffusion and Computer Vision, alongside a strong background in Web Development using JavaScript, specializing in ReactJS. I'm skilled in solving complex software and hardware challenges and consider myself a responsible, punctual, and reliable team player." 
                />
            </div>
        </div>
    );
}

export default Team;