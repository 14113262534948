import { faHandshake, faScrewdriverWrench, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import Cue from "../../assets/icons/cue_white.svg";
import { Card } from "../../components";
import "./about.css";

const About = () => {

    return (
        <div id="about" className="about">
            <h2>About Us</h2>
            <p className="about__first-paragraph">
                <span className="about__highlight--blue">WILJO</span> is a full service vending company providing 
                <span className="about__highlight--red">Video Games</span>, 
                <span className="about__highlight--green">Pool Tables</span>, 
                <span className="about__highlight--yellow">Jukeboxes</span>, 
                <span className="about__highlight--pink">ATM’s</span> and more machinery to the bars and restaurant community.
            </p>
            <img src={Cue} alt="Cue Icon" role="presentation" />
            <p className="about__second-paragraph">
                We have been in existence for <span className="about__highlight--blue about__number">7</span> years and the owners have over <span className="about__highlight--blue about__number">30</span> years of experience in the industry.
            </p>
            <div className="about__card-container">
                <Card text="All the installation of equipment is done at no cost to you." icon={faDollarSign} />
                <Card text="Professional repair services available." icon={faScrewdriverWrench} />
                <Card text="We offer excellent customer service." icon={faHandshake} />
            </div>
            <p className="about__third-paragraph">
                We understand the importance of maintaining and keeping equipment up and running. We make sure machine repair is executed in a timely fashion.
            </p>
        </div>
    );
}

export default About;