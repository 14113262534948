import { faEnvelope, faMapLocation, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import "./contact.css";

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [feedback, setFeedback] = useState('');
    const [showNotification, setShowNotification] = useState(false);

    const handleForm = (e) => {
        e.preventDefault();

        // Basic validation
        if (!name || !email || !phone || !message) {
            setFeedback('Please fill in all fields.');
            setShowNotification(true);
            return;
        }

        const serviceId = '';
        const templateId = '';
        const publicKey = '';

        const templateParams = {
            from_name: name,
            from_email: email,
            from_phone: phone,
            to_name: 'WILJO',
            message: message,
        };

        emailjs.send(serviceId, templateId, templateParams, publicKey)
            .then((response) => {
                console.log("Email sent successfully!", response);
                setName('');
                setEmail('');
                setPhone('');
                setMessage('');
                setFeedback('Thank you for your email! We will get back to you shortly.');
                setShowNotification(true);
            })
            .catch((error) => {
                console.log("Error sending email: ", error);
                setFeedback('Oops! Something went wrong. Please try again later.');
                setShowNotification(true);
            });
    };

    useEffect(() => {
        if (showNotification) {
            const timer = setTimeout(() => {
                setShowNotification(false);
            }, 5000); // Hide notification after 5 seconds

            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [showNotification]);

    return (
        <div id="contact" className="contact">
            <h2>Contact Us</h2>
            <div className="contactForm">
                <form onSubmit={handleForm}>
                    <div className='cont_contact_header'>
                        <h1 className="sub-heading">Need Support?</h1>
                        <p className="para para2">Contact us for a quote.</p>
                    </div>
                    <input 
                        id="name"
                        type="text" 
                        className="input"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Your Name" 
                        autoComplete="name"
                    />
                    <input 
                        id="email"
                        type="email" 
                        className="input" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Your Email" 
                        autoComplete="email"
                    />
                    <input 
                        id="phone"
                        type="tel" 
                        className="input"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Your Phone" 
                        autoComplete="tel"
                    />
                    <textarea 
                        id="message"
                        className="input" 
                        cols="30" 
                        rows="5"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Your Message..."
                        autoComplete="off"
                    ></textarea>
                    <input type="submit" className="input submit" value="Send Message" />
                </form>

                {showNotification && (
                    <div className="notification">
                        <p>{feedback}</p>
                    </div>
                )}

                <div className="map-container">
                    <div className="mapBg"></div>
                    <div className="map">
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193596.00927566906!2d-74.14448686377237!3d40.69737092183334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNueva%20York%2C%20EE.%20UU.!5e0!3m2!1ses-419!2smx!4v1709534513769!5m2!1ses-419!2smx" 
                            width="600" 
                            height="450" 
                            style={{ border: 0 }} 
                            allowFullScreen="" 
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade"
                            title="Map of New York City"
                        ></iframe>
                    </div>
                </div>
            </div>

            <div className="contactMethod">
                <div className="method">
                    <FontAwesomeIcon className='icon contactIcon' icon={faMapLocation} />
                    <article className="text">
                        <h1 className="sub-heading">Location</h1>
                        <p className="para">New York City, New York.</p>
                    </article>
                </div>

                <div className="method">
                    <FontAwesomeIcon className='icon contactIcon' icon={faEnvelope} />
                    <article className="text">
                        <h1 className="sub-heading">Email</h1>
                        <p className="para">wiljoone@yahoo.com</p>
                    </article>
                </div>

                <div className="method">
                    <FontAwesomeIcon className='icon contactIcon' icon={faPhone} />
                    <article className="text">
                        <h1 className="sub-heading">Phone</h1>
                        <p className="para">(929) 502-0337</p>
                    </article>
                </div>
            </div>
        </div>
    );
}

export default Contact;