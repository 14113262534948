import {Landing, About, Products, Team, Contact} from "./containers"
import {Navbar} from "./components"
import './App.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Landing />
      <About />
      <Products />
      <Team />
      <Contact />
    </div>
  );
}

export default App;
