import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import './card.css';

const Card = ({ text, icon }) => {
    return (
        <div className="card">
            <FontAwesomeIcon className='icon' icon={icon} aria-label={text} />
            <p>{text}</p>
        </div>
    );
}

Card.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
};

export default Card;